@import "project-settings";
/*
*	define here workarounds and fixes for existing styles
*/

/*
*	fix wrong button-color
*/
.button
{
	text-decoration: none;
	a
	{
		color: $button-color;
		
	}
}

.primary
{
	[type='submit'], [type='button']
	{
		@include button();	
	}
}

.button.bordered
{
	@include button-style($body-background, $primary-color, $white);
	@include button-bordered();
}

.menu.medium-horizontal>li
{
	@include breakpoint(medium up)
	{
		display: inline !important;
		float: left;
	}
}
.wp-editor
{
	height: auto !important;
}

.wpcf7-submit
{
	@include button($default-padding/2, $primary-color);
}

iframe
{
	max-width: 100%;
}

.no-js .top-bar {
 display: none;
}
.reveal {
	color: $primary-color;
	label {
		color: $primary-color;
	}
}
table {
	tbody {
		tr {
			th {
				color: $primary-color;

			}
			td {
				color: $primary-color;
			}
			a {
				color: darken($secondary-color, 10%);
				&:hover {
					color: darken($secondary-color, 15%);
				}
			}
		}
	}
}